import '/slick.css'
import '/slick-theme.css'
import React from 'react'
import Slider from 'react-slick'
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
    className={`${className} CustomNextArrow`}
    style={style}
    onClick={onClick}
    >
      <BiRightArrow />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} CustomPrevArrow`}
      style={style}
      onClick={onClick}
    >
      <BiLeftArrow />
    </div>
  );
}

const AboutSlider = () => {
  const images = [
    '/images/about_1.jpg',
    '/images/about_2.jpg',
    '/images/about_3.jpg',
    '/images/about_4.jpg',
    '/images/about_5.jpg',
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchMove: true,
    speed: 300,
    touchThreshold: 3.2,
    // autoplay: true,
    // autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div className='SliderItem' key={index}>
          <img className='SliderImg' src={image} alt="Slide" />
        </div>
      ))}
    </Slider>
  );
};

export default AboutSlider;