import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import mapboxgl from 'mapbox-gl';
import ContactForm from './ContactForm.jsx';

gsap.registerPlugin(ScrollTrigger);

export default function Contact() {


    const mapWrapRef = useRef(null);
    const contactRef = useRef(null);
    const messageRef = useRef(null);
    const workRef = useRef(null);
    const adressRef = useRef(null);
    const formRef = useRef(null);
    const buttonScrollRef = useRef(null);
  
    const [isReady, setIsReady] = useState(false);
    const [scrollZoomEnabled, setScrollZoomEnabled] = useState(false);

    useEffect(() => {
      const mapWrap = mapWrapRef.current;
      const buttonScroll = buttonScrollRef.current;
      const message = messageRef.current;
      const work = workRef.current;
      const adress = adressRef.current;
      const form = formRef.current;
  
      let timeline1 = gsap.timeline({
        delay: 2,
        defaults: {
          opacity: 0,
          duration: 0.5,
          ease: 'power3.out',
        }
      });
  
      timeline1.from(work, {
        y: 50,
      });
  
      timeline1.from(adress, {
        y: 50,
      }, '-=0.3');
  
      timeline1.from(form, {
        y: 50,
      }, '-=0.3');




      gsap.from(mapWrap, {
        scrollTrigger: {
          trigger: mapWrap,
          start: 'top 90%',
          toggleActions: 'play none none none',
          // markers: true,
        },
        opacity: 0,
        x: -100,
        duration: 0.5,
        delay: 0.3,
        ease: 'power3.out'
      });

      gsap.from(buttonScroll, {
        scrollTrigger: {
          trigger: mapWrap,
          start: 'top 90%',
          toggleActions: 'play none none none',
          // markers: true,
        },
        opacity: 0,
        x: 100,
        delay: 0.5,
        duration: 0.5,
        ease: 'power3.out'
      });

    }, []);
  
    useEffect(() => {
      mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kcmVhc3BhbnRlbCIsImEiOiJjbGpvZzc3NzkwejB1M3NuM3N5bnp6MmcwIn0.kuHVhm_QKv4IDkVTb9NoTQ';
  
      const map = new mapboxgl.Map({
        container: 'map-container',
        style: 'mapbox://styles/andreaspantel/cljom1yww00ny01r51bqfazfn',
        center: [9.47125, 48.92780],
        zoom: 8.5,
      });
  
      if (scrollZoomEnabled) {
        map.scrollZoom.enable();
      } else {
        map.scrollZoom.disable();
      }
  
      map.addControl(new mapboxgl.NavigationControl());
  
      map.on('load', () => {
        // Load an image from an external URL.
        map.loadImage('/images/location_ap_bright.png', (error, image) => {
          if (error) throw error;
  
          // Add the image to the map style.
          map.addImage('location_ap', image);
  
          // Add a data source containing one point feature.
          map.addSource('point', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [9.47125, 48.92780],
                  },
                },
              ],
            },
          });
  
          // Add a layer to use the image to represent the data.
          map.addLayer({
            id: 'points',
            type: 'symbol',
            source: 'point', // reference the data source
            layout: {
              'icon-image': 'location_ap', // reference the image
              'icon-size': 1,
            },
          });
        });
      });
  
      return () => {
        map.remove(); // Clean up the map instance when the component unmounts
      };
    }, [scrollZoomEnabled]);
  



    const toggleScrollZoom = () => {
      setScrollZoomEnabled((prevState) => !prevState);
    };
    

    return (
        <>
          <div className="Wrapper ContactWrapper" ref={messageRef}>
            <div>
              <div ref={workRef}>
                <h5>Contact</h5>
                <h1>Let's work<br />together</h1>
              </div>
              <div className="Adress" ref={adressRef}>
                <h5>Adress</h5>
                <p>Andreas Pantel</p>
                <p>71554 Weissach im Tal</p>
                <p>Germany, EU</p>
              </div>
            </div>
            <ContactForm ref={formRef} />
          </div>
          <div className="Wrapper MapWrapper" ref={contactRef}>
              <div className='MapContainer' id="map-container" ref={mapWrapRef}>
                <button
                  className='ScrollZoomButton'
                  onClick={toggleScrollZoom}
                  ref={buttonScrollRef}
                >
                  {scrollZoomEnabled ? 'Disable Scroll Zoom' : 'Enable Scroll Zoom'}
                </button>
              </div>
          </div>
        </>
);
}