import GalleryItems from './GalleryItems.jsx';

export default function Gallery() {


  return (
    <div className="HomeContainer Gallery">
      <a id="gallery"></a>
      <div className='Wrapper'>
        <div className='SectionTitle Animated'>
          <h5>Selected Projects</h5>
          <h1>My Portfolio</h1>
        </div>
      </div>
      <div className='Wrapper'>
        <div className='GalleryWrapper'>
            <GalleryItems />
        </div>
      </div>
    </div>
  );
}
