import Cta from '/components/elements/Cta.jsx'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'


export default function Sneaker()
{

  
    return(
        <>
            <div className="HomeContainer">
                <div className="Wrapper PortfolioWrapper" id='sneaker-wrapper'>
                    <div className='Sneaker' id='sneaker'>
                        <Canvas
                            shadows
                            camera={{
                                fov: 65,
                                near: 0.1,
                                far: 100,
                                position: [ 0, 0, -15 ],
                            }}
                        >
                            <Experience />
                        </Canvas>
                    </div>
                </div>
            </div>
            <Cta />
        </>
    )
}