import React, { useRef, useState, forwardRef } from 'react';
import emailjs from '@emailjs/browser';


const ContactForm = forwardRef((props, ref) => {
  const form = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current['name'].value;
    const email = form.current['email'].value;
    const message = form.current['message'].value;

    if (!name || !email || !message) {
      setErrorMessage('Please fill in all fields');
      return;
    }

    emailjs
      .sendForm(
        'service_22s4mrj',
        'template_g5im6os',
        form.current,
        'kVF5qbC8yb0r_C5pt'
      )
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        setErrorMessage('');
        setSuccessMessage('Thank you for your message !');
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage('An error occurred while sending the email');
        setSuccessMessage('');
      });
  };

  return (
    <div className='ContactForm' ref={ref}>
      <form name='contact-form' ref={form} onSubmit={sendEmail}>
        <div className='InputForm'>
          <label>Your Name</label>
          <input className='InputData' type='text' name='name' placeholder='...' />
        </div>
        <div className='InputForm'>
          <label>Your EMail</label>
          <input className='InputData' type='email' name='email' placeholder='...' />
        </div>
        <div className='InputForm'>
          <label>Your Message</label>
          <textarea className='InputText' name='message' placeholder='...' />
        </div>
        <div>
          <button className='InputButton' type='submit'>Send</button>
        </div>
        {errorMessage && <p className='ErrorMessage'>{errorMessage}</p>}
        {successMessage && <p className='SuccessMessage'>{successMessage}</p>}
      </form>
    </div>
  );
});

export default ContactForm;