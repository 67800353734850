import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} CustomNextArrow`} style={style} onClick={onClick}>
      <BiRightArrow />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} CustomPrevArrow`} style={style} onClick={onClick}>
      <BiLeftArrow />
    </div>
  );
}

// function SampleNextArrow2(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div className={`${className} CustomNextArrow2`} style={style} onClick={onClick}>
//       <BiRightArrow />
//     </div>
//   );
// }

// function SamplePrevArrow2(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div className={`${className} CustomPrevArrow2`} style={style} onClick={onClick}>
//       <BiLeftArrow />
//     </div>
//   );
// }

const GalleryOverlaySlider = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [sliderVisible, setSliderVisible] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const images = [
    '/images/3d-cigarroom_1.jpg',
    '/images/3d-cigarroom_2.jpg',
    '/images/3d-officevenice_1.jpg',
    '/images/3d-officevenice_2.jpg',
    '/images/3d-myroom.jpg',
    '/images/3d-archvisUnreal.jpg',
    '/images/3d-kitchen.jpg'
  ];

  const handleSlider1Ref = slider => {
    setNav1(slider);
  };

  const handleSlider2Ref = slider => {
    setNav2(slider);
  };

  const handleImageLoaded = () => {
    setImagesLoaded(prevCount => prevCount + 1);
  };


  return (
    <>
      <Slider
        asNavFor={nav2}
        ref={handleSlider1Ref}
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
        className="Slider1"
        // Hide the slider if images are not loaded
        // style={{ opacity: sliderVisible ? 1 : 0 }}
      >
        {images.map((image, index) => (
          <div className="SliderItem" key={index}>
            <img
              className="SliderImg"
              src={image}
              alt="Slide"
              onLoad={handleImageLoaded} // Increment loaded images count when image is loaded
            />
          </div>
        ))}
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={handleSlider2Ref}
        slidesToShow={5}
        swipeToSlide={true}
        focusOnSelect={true}
        // nextArrow={<SampleNextArrow2 />}
        // prevArrow={<SamplePrevArrow2 />}
        className="Slider2"
        // Hide the slider if images are not loaded
        style={{ opacity: sliderVisible ? 1 : 0 }}
      >
      </Slider>
    </>
  );
};

export default GalleryOverlaySlider;
