import React, { useRef, useState, useEffect } from 'react';
import { gsap, TweenMax } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import GalleryOverlaySlider from './GalleryOverlaySlider.jsx'
import GalleryItemsData from './GalleryItemsData.jsx';

gsap.registerPlugin(ScrollTrigger);

export default function GalleryItems() {
  
  // Active Item
  const [activeItem, setActiveItem] = useState(null);
  const [overlayActive, setOverlayActive] = useState(false);
  const galleryItems = GalleryItemsData;
  const itemRefs = useRef([]);

  useEffect(() => {
    const handleOverflowClass = () => {
      if (activeItem) {
        document.documentElement.classList.add('overflow-hidden');
        document.body.classList.add('overflow-hidden');
        document.getElementById('root').classList.add('overflow-hidden');
      } else {
        document.documentElement.classList.remove('overflow-hidden');
        document.body.classList.remove('overflow-hidden');
        document.getElementById('root').classList.remove('overflow-hidden');
      }
    };

    handleOverflowClass();
  }, [activeItem]);

  useEffect(() => {
    // Scrolltrigger
    const itemElements = itemRefs.current.map((item) => item.element);

    itemElements.forEach((element, index) => {
      gsap.from(element, {
        scrollTrigger: {
          trigger: '.GalleryWrapper',
          start: 'top 40%',
          // end: '1000px',
          toggleActions: 'play none none none',
          // markers: true,
          onEnter: () => {
            ScrollTrigger.refresh();
          }
        },
        opacity: 0,
        y: 75,
        delay: 0.2 + index * 0.3,
        duration: 0.5,
        ease: 'power3.out',
      }, '-=0.3');

    });
  }, []);

  // Handle Click Functions
  const handleClick = (item) => {
    if (item.ext) {
      if (item.openInNewTab) {
        // Open in new tab
        window.open(item.extUrl, "_blank");
      } else {
        // Load URL in current tab
        window.location.href = item.extUrl;
      }
    } else {
      setActiveItem((prevActiveItem) => {
        if (prevActiveItem && prevActiveItem.id === item.id) {
          setOverlayActive(true);
          return null;
        } else {
          setOverlayActive(true);
          return item;
        }
      });
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('CloseOverlay')) {
      setOverlayActive(false);
      setActiveItem(null);
    }
  };

  const handleItemRef = (item, element) => {
    if (element) {
      itemRefs.current.push({ id: item.id, element });
  
      // Check if the screen width is greater than 1280px before attaching listeners
      if (window.innerWidth > 1280) {
        // Hover Animation
        element.addEventListener('mouseenter', handleMouseEnter);
        element.addEventListener('mouseleave', handleMouseLeave);
      }
    }
  };

  const handleMouseEnter = (event) => {
    const element = event.currentTarget;
  
    // Check if Desktop Anim should be played (mobile not)
    if (window.innerWidth > 1400) {
      element.querySelector('.HiddenMore').style.display = 'block';
    }

    // WorkDescRegular Animation
    const workDescRegular = element.querySelector('.WorkDescRegular');
    gsap.killTweensOf(workDescRegular);
    gsap.to(workDescRegular, { opacity: 0, duration: 0.3 });
  
    // WorkDescAction Animation
    const workDescAction = element.querySelector('.WorkDescAction');
    gsap.killTweensOf(workDescAction);
    gsap.to(workDescAction, { y: 0, opacity: 1, duration: 0.3 });
  
    // ViewMore Animation
    const viewMore = element.querySelector('.ViewMore');
    gsap.to(viewMore, { y: 0, opacity: 1, duration: 0.3 });
  
    // ViewMore Span Animation
    const viewMoreSpan = element.querySelector('.ViewMore span');
    gsap.to(viewMoreSpan, { x: 20, opacity: 1, duration: 0.15 });
  };

  const handleMouseLeave = (event) => {
    const element = event.currentTarget;
  
    // WorkDescRegular Animation
    const workDescRegular = element.querySelector('.WorkDescRegular');
    gsap.killTweensOf(workDescRegular);
    gsap.set(workDescRegular, { opacity: 1 });
  
    // WorkDescAction Animation
    const workDescAction = element.querySelector('.WorkDescAction');
    gsap.killTweensOf(workDescAction);
    gsap.set(workDescAction, { y: 50, opacity: 0 });
  
    // ViewMore Animation
    const viewMore = element.querySelector('.ViewMore');
    gsap.killTweensOf(viewMore);
    gsap.to(viewMore, { y: 50, opacity: 0, duration: 0.2 });
  
    // ViewMore Span Animation
    const viewMoreSpan = element.querySelector('.ViewMore span');
    gsap.killTweensOf(viewMoreSpan);
    gsap.to(viewMoreSpan, { x: 0, opacity: 0, duration: 0.2 });
  };

  return (
    <>
      {galleryItems.map((item) => (
        <div
          key={item.id}
          className={`GalleryItem ${item.name} ${activeItem === item.id ? ' active' : ''}`}
          onClick={() => handleClick(item)}
          ref={(element) => handleItemRef(item, element)}
        >
          <a className='ItemLink'>
            <div className='WorkDescRegular'>
              <span className='h5'>{item.date}</span>
              <span className='h2'>{item.cat}</span>
              {/* <span className='h5'>{item.title}</span> */}
            </div>
            <div className='WorkDescAction'>
              <span className='h5'>{item.date}</span>
              <span className='h2'>{item.cat}</span>
              {/* <span className='h5'>{item.title}</span> */}
            </div>
            <div className='ViewMore HiddenMore'>
              <p>
                View Project <span className='Boxicon'><box-icon name='right-arrow-alt'></box-icon></span>
              </p>
            </div>
            <div className='WorkThumbnail'>
              <img src={item.src} alt={`Work ${item.id}`} />
            </div>
          </a>
        </div>
      ))}
      {activeItem !== null && overlayActive && (
        <div 
          className="GalleryOverlay active" 
          onClick={handleOverlayClick}
        >
          <div className='SliderWrapper OverlaySlider'>
            <GalleryOverlaySlider />
            <div className='CloseOverlay'>Close</div>
          </div>
        </div>
      )}
    </>
  );
}