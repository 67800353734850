/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function SneakerModel(props) {
  const { nodes, materials } = useGLTF("/sneaker_allbird.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sohle_bottom001.geometry}
        material={materials["sohleBottom.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sohle_top001.geometry}
        material={materials["sohleTop.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.NurbsCurve002.geometry}
        material={materials["Bendel.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={materials["Patch.001"]}
        position={[2.945, 2.759, -0.151]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials.Naht}
        position={[3.307, 1.722, -0.639]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Sohle_top002.geometry}
        material={materials["Patch.001"]}
        position={[0, 0.052, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Torus008.geometry}
        material={materials["torusMat.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Torus008_1.geometry}
        material={materials["torusMat_Inside.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003_1.geometry}
        material={materials["stoffTop.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003_2.geometry}
        material={materials["stoffBottom.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003_3.geometry}
        material={materials.Patch}
      />
    </group>
  );
}

useGLTF.preload("./sneaker_allbird.glb");
