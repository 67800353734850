import React from 'react';

const GalleryItemsData = [
  {
    id: 1,
    name: 'ab',
    date: '2023',
    cat: 'Mobile UX & UI',
    title: '"PeakPerformance"',
    src: '/images/workThumbnail_1.jpg',
    ext: true,
    extUrl: '/portfolio/easyevent',
    openInNewTab: false
  },
  {
    id: 2,
    name: 'ad',
    date: '2023',
    cat: 'Webdesign',
    title: '"Taverna Santorin"',
    src: '/images/workThumbnail_3.jpg',
    ext: true,
    extUrl: '/portfolio/tavernasantorin',
    openInNewTab: false
  },
  {
    id: 3,
    name: 'ac',
    date: '2023',
    cat: '3D - Three.js',
    title: '"Sneaker Configurator"',
    src: '/images/workThumbnail_2.jpg',
    ext: true,
    extUrl: '/portfolio/sneaker',
    openInNewTab: false
  },
];

export default GalleryItemsData;