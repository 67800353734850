export default function Footer()
{
    return(
        <>
            <div className="HomeContainer">
                <div className="Wrapper">
                    <div className="FooterWrapper">
                        <div className="FooterElement">
                            <h4>Contact<br />Information</h4>
                            <p>
                                Feel free to contact me any time.<br />
                                I prefer to talk via Email, since we may be time zones away.
                            </p>
                            <p>
                                Email: 
                                <a 
                                    style={{margin: "0.5rem"}}
                                    href="mailto:andreaspantel.inbox@gmail.com"
                                >
                                    andreaspantel.inbox@gmail.com
                                </a>
                            </p>
                        </div>
                        <div className="FooterElement">
                            <h4>Current<br />availability</h4>
                            <p>
                                I usually work on several projects<br />but I'll be happy to discuss new<br />opportunities and get in touch!
                            </p>
                        </div>
                        <div className="FooterElement">
                            <h4>Latest<br />projects</h4>
                            <p><a href="/portfolio/easyevent">Mobile UX & UI</a></p>
                            <p><a href="/portfolio/tavernasantorin">Webdesign</a></p>
                            <p><a href="/portfolio/sneaker">Three.js</a></p>
                        </div>
                        <div className="FooterElement">
                            <h4>Follow<br />me on</h4>
                            <p><a href="https://www.linkedin.com/in/andreas-pantel/" target="_blank">LinkedIn</a></p>
                            <p><a href="https://dribbble.com/" target="_blank">Dribble</a></p>
                            <p><a href="https://www.artstation.com/andi_pantel" target="_blank">ArtStation</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}