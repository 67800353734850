import React, { useState, useEffect } from 'react';
import Cta from '/components/elements/Cta.jsx';

export default function TavernaSantorin() {
  const [scrollOpacity, setScrollOpacity] = useState(0);

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      // Calculate the scroll position
      const scrollY = window.scrollY || window.pageYOffset;

      // Calculate the opacity based on the scroll position
      const opacity = scrollY >= 0.8 * window.innerHeight ? 1 : 0;

      // Update the scrollOpacity state
      setScrollOpacity(opacity);
    };

    // Add a scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="HomeContainer">
        <div className="Wrapper PortfolioWrapper">
          <img src="/images/tavernasantorinPage.png" alt="TavernaSantorin Page" />
        </div>
        <a href="#" className='UpScroller' style={{ opacity: scrollOpacity }}>
          <box-icon className='BoxiconArrowUp' size='24px' name='up-arrow-alt'></box-icon>
        </a>
      </div>
      <Cta />
    </>
  );
}
