import './style.css'
import 'boxicons'
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import gsap from 'gsap';
import Nav from './components/elements/Nav.jsx'
import Home from './components/home/Home.jsx'
import EasyEvent from './components/portfolio/EasyEvent.jsx'
import TavernaSantorin from './components/portfolio/TavernaSantorin.jsx'
import Sneaker from './components/portfolio/3DViewer/Sneaker.jsx';
import About from './components/about/About.jsx';
import Contact from './components/contact/Contact.jsx';
import Footer from './components/elements/Footer.jsx'
import PreLoader from './components/PreLoader.jsx';



const root = ReactDOM.createRoot(document.querySelector('#root'));

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const fixedInnerDivRef = useRef(null);
  const fixedDivRef = useRef(null);

  useEffect(() => {
    const fixedInnerDiv = fixedInnerDivRef.current;

    // Animation timeline
    const timeline = gsap.timeline({
      paused: true,
      defaults: {
        delay: 1.4,
        duration: 1,
        ease: 'power3.out',
      },
    });

    // Animation from height 100 to 20
    timeline.fromTo(
      fixedInnerDiv,
      { height: '100%' },
      { height: '20%' }
    );

    // Simulate loading delay of 2 seconds
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Start animation when loader is finished
    if (!isLoading) {
      setIsAnimationComplete(true);
      timeline.play();
    }

    return () => {
      clearTimeout(delay);
    };
  }, [isLoading]);

  useEffect(() => {
    if (isAnimationComplete) {
      const handleScroll = () => {
        const scrollProgress =
          (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 0.8;
        const windowHeight = window.innerHeight;
        const newHeight = windowHeight * scrollProgress + windowHeight * 0.2;

        gsap.to(fixedInnerDivRef.current, {
          height: newHeight,
          duration: 0.5,
        });
      };

      const handleScrollbar = () => {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

        if (scrollbarWidth > 0) {
          fixedDivRef.current.classList.add('hidden-scrollbar');
        } else {
          fixedDivRef.current.classList.remove('hidden-scrollbar');
        }
      };

      window.addEventListener('scroll', handleScroll);
      handleScrollbar(); // Initial check on component mount

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isAnimationComplete]);
  


    return (
      <Router basename='/'>
        <PreLoader />
        {!isLoading && (
          <>
            <Nav />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/portfolio/easyevent" element={<EasyEvent />} />
              <Route path="/portfolio/tavernasantorin" element={<TavernaSantorin />} />
              <Route path="/portfolio/sneaker" element={<Sneaker />} />
            </Routes>
            <Footer />
    
            <div id="fixed-div" ref={fixedDivRef} className='hidden-scrollbar'>
              <div id="fixed-inner-div" ref={fixedInnerDivRef}>
              </div>
            </div>
          </>
        )}
      </Router>
    );
};

root.render(<App />);