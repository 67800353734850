import { Sparkles, useGLTF, SoftShadows, BakeShadows, useHelper, OrbitControls } from "@react-three/drei"
import { useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import * as THREE from 'three'
import SneakerModel from './SneakerModel.jsx'
import { useControls } from 'leva'



export default function Experience()
{

    // Leva Controls
    // const controls = useControls({
    //   spotColor2: [0, 0.5, 1],
    //   spotColor1: [1, 0, 0.5],
    //   torusColor1: [0, 0.5, 1],
    //   torusColor2: [1, 0, 0.5],
    //   cylinderSpeed: '0.25'
    // });

    // Import Sneaker Model
    const model = useGLTF('/sneaker_allbird.glb')

    // Light Helper
    const spotLightRef = useRef()
    // useHelper(spotLightRef, THREE.SpotLightHelper, .1)

    const spotLightRef2 = useRef()
    // useHelper(spotLightRef2, THREE.SpotLightHelper, .1)

    // const directionalLightRef = useRef()
    // useHelper(directionalLightRef, THREE.DirectionalLightHelper, .1)


    // Animation Cylinder
    const cylinderRef = useRef()
    let cylinderSpped = 0.25

    useFrame((state, delta) =>
    {
        cylinderRef.current.rotation.y += delta * 0.25 // -> delta <- for animation
    })
    


    return(
        <>

            <color args={ [ '#333' ] } attach="background" />     

            <OrbitControls makeDefault />

            <ambientLight
                intensity={ 0.5 }
            />            
            <directionalLight
                // ref={ directionalLightRef } 
                position={[ 0, 3, -1 ]} 
                intensity={ 1 }  
                castShadow
                shadow-mapSize={[ 1024, 1024 ]}
                shadow-camera-near={ 1 }
                shadow-camera-far={ 20 }
                shadow-camera-top={ 5 }
                shadow-camera-right={ 5 }
                shadow-camera-bottom={ - 5 }
                shadow-camera-left={ - 5 }
                shadow-normalBias={ 0.04 }
            />
            <spotLight 
                ref={spotLightRef}
                position={[ 11, 4, 0 ]}
                color={[1, 0, 0.5]}
                intensity={ 20 }
                angle={ Math.PI * 0.25 }
                distance={ 20 }
                decay={2}
            />
            <spotLight 
                ref={spotLightRef2}
                position={[ -11, 4, 0 ]}
                color={[0, 0.5, 1]}
                intensity={ 25 }
                angle={ Math.PI * 0.25 }
                distance={ 20 }
                decay={2}
            />
            <BakeShadows />
            <SoftShadows 
                frustum={ 1 } 
                size={ 25 } 
                near={ 1 } 
                samples={ 25 } 
                rings={ 15 }
                focus={ 6 }
            />
            <Sparkles
                speed={ 1.5 }
                count={ 100 }
                size={ 10 }
                scale={ 18 }
                position-y={ 1 }
                noise={ 0.7 }
                color={'orange'}
                opacity={ 0.2 }
            />

            <mesh 
              position-y={ - 3.5 } 
              scale={ 1 } 
              ref={ cylinderRef }
              receiveShadow
            >
                <cylinderGeometry 
                    args={[ 7, 7, 0.5, 6 ]} 
                />
                <meshStandardMaterial
                    color='#333'
                    roughness={ 0.5 }
                    metalness={ 0.5 }
                    flatShading
                /> 
            </mesh> 

            <SneakerModel 
                scale={ 1 } 
                position={[ 0, -2, 0 ]}
                castShadow
            />

            <mesh
                position={[ -8, 4, 0 ]}
                rotation={[ Math.PI * 1.5, Math.PI * 1.75, 0 ]}
            >
                <torusGeometry 
                    args={[ 4, 0.2, 24, 128 ]}
                />
                <meshStandardMaterial 
                    color={[0, 0.5, 1]}
                    roughness={ 0.8 }
                    toneMapped={ false }
                    emissive={[0, 0.5, 1]}
                    emissiveIntensity={ 1 }
                />
            </mesh>

            <mesh
                position={[ 8, 4, 0 ]}
                rotation={[ Math.PI * 1.5, Math.PI * 1.25, 0 ]}
            >
                <torusGeometry 
                    args={[ 2.5, 0.2, 24, 128 ]}
                />
                <meshStandardMaterial 
                    color={[1, 0, 0.5]}
                    roughness={ 0.8 }
                    toneMapped={ false }
                    emissive={[1, 0, 0.5]}
                    emissiveIntensity={ 1 }
                />
            </mesh>

        </>
    )
}