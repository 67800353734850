import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger)

export default function Landing({ setIsAnimationComplete  })
{

    const homeRef = useRef(null);
    const copyRef = useRef(null);
    const h3Ref = useRef(null);
    const scrollerRef = useRef(null);
    const linksRef = useRef(null);
    const imgRef = useRef(null);
    const bgRef = useRef(null);
  
    // PreLoader state
    const [isReady, setIsReady] = useState(false);


    useEffect(() => {
      const home = homeRef.current;
      const copy = copyRef.current;
      const h3 = h3Ref.current;
      const scroller = scrollerRef.current;
      const links = linksRef.current;
      const img = imgRef.current;
      const bg = bgRef.current;
  
      let timeline1 = gsap.timeline({
        delay: 2,
        defaults: {
          opacity: 0,
          duration: 0.5,
          ease: 'power3.out',
        },
      });
  
      timeline1.from(img, { x: 50 });
      timeline1.from(copy, { y: 30 }, '-=0.3');
      timeline1.from(h3, { y: 30 }, '-=0.3');
      timeline1.from(links, { y: 30 }, '-=0.3');
      timeline1.from(scroller, { y: 30 }, '-=0.3');
      timeline1.from(bg, { y: -20, x: -20 }, '-=0.3');
  

    }, []);



    return(
        <div className="HomeContainer Landing" id="home" ref={homeRef}>
            <div className='BgShadow' ref={bgRef}>design</div>
            <div className='ImgPortrait' ref={imgRef}>
                
            </div>

            <div className='Wrapper HomeWrapper'>
                <div className='MainCopy' ref={copyRef}>
                    <h5>Andreas Pantel</h5>
                    <h1>Web, 3D and UX & UI Designer</h1>
                    <h3 ref={h3Ref}>
                        With a focus on web, 3D, user experience and interface design, I strive to create clean code and visually immersive content that not only captures attention but also evokes emotion and leaves a visual imprint.
                    </h3>
                    <div className='Links' ref={linksRef}>
                        <h4><a href="#gallery">View Projects</a></h4>
                        <span>or</span>
                        <h4><Link reloadDocument to="/about">Read About Me</Link></h4>
                    </div>
                </div>
            </div>

            <div className='ScrollWrapper' ref={scrollerRef}>
                <a 
                  href="#gallery" 
                  className='ScrollLink'
                >
                    <svg className='Mouse' xmlns="http://www.w3.org/2000/svg" width="36" height="40" viewBox="0 0 24 24" style={{ fill: "none",
                    stroke: "var(--dark)", 
                    strokeWidth: 1.2,
                    transform: "", 
                    msFilter: "" }}>
                      <path 
                        className='MouseCorpus'
                        d="M12,22L12,22c3.9,0,7-3.1,7-7V9c0-3.8-3.1-7-7-7H12C8.1,2,5,5.2,5,9v6C5,18.9,8.1,22,12,22z"></path>
                      <path       
                        className='MouseButton' d="M11.5,6h1v6h-1V6z"
                      >
                      </path>
                    </svg>
                </a>
            </div>

        </div>
    )
}