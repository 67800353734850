
import React, { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import AboutSlider from './AboutSlider'
import Cta from '/components/elements/Cta.jsx'

gsap.registerPlugin(ScrollTrigger);

export default function About()
{
    const aboutRef = useRef(null);
    const skillsRef = useRef(null);
    const expRef = useRef(null);
    const sliderRef = useRef(null);
    const aboutSliderRef = useRef(null);
 
    // PreLoader state
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
      const about = aboutRef.current;
      const skills = skillsRef.current;
      const exp = expRef.current;
      const slider = sliderRef.current;
      
      let timeline1 = gsap.timeline({
        delay: 2,
        defaults: {
          opacity: 0,
          duration: 0.5,
          ease: 'power3.out',
        }
      });
  
      timeline1.from(about, {
        y: 50,
        opacity: 0,
      });

      gsap.from(skills, {
        scrollTrigger: {
          trigger: skills,
          start: 'top 90%',
          toggleActions: 'play none none none',
          // markers: true,
        },
        opacity: 0,
        y: 50,
        duration: 0.5,
        delay: 0.3,
        ease: 'power3.out'
      });

      gsap.from(exp, {
        scrollTrigger: {
          trigger: exp,
          start: 'top 90%',
          toggleActions: 'play none none none',
          // markers: true,
        },
        opacity: 0,
        y: 50,
        duration: 0.5,
        delay: 0.3,
        ease: 'power3.out'
      });

      gsap.from(slider, {
        scrollTrigger: {
          trigger: slider,
          start: 'top 90%',
          toggleActions: 'play none none none',
          // markers: true,
        },
        opacity: 0,
        y: 50,
        duration: 0.5,
        delay: 0.3,
        ease: 'power3.out'
      });
  
      const handleScroll = () => {
        if (!isReady) return; // Do nothing until the preloader is complete
  
        const scrollPosition = window.pageYOffset + window.innerHeight;
  
        if (scrollPosition >= about.offsetTop - 300) {
          timeline1.play(); // Start the ScrollTrigger animation
          ScrollTrigger.removeEventListener('scroll', handleScroll); // Remove the scroll event listener once triggered
        }
      };
  
      // Initialize the ScrollTrigger
      ScrollTrigger.create({
        trigger: about,
        start: 'top+=300 top',
        end: 'bottom top',
        onEnter: () => {
          if (isReady) {
            timeline1.play();
          }
        },
      });
  
      // Simulate loading delay of 3 seconds
      const delay = setTimeout(() => {
        setIsReady(true);
        timeline1.play();
        ScrollTrigger.removeEventListener('scroll', handleScroll);
      }, 2000);
  
      return () => {
        clearTimeout(delay);
      };
    }, []);

    return(
        <>
            <div className="HomeContainer About" id="about">
              <div className='BgShadow'>about</div>
              <div className="Wrapper AboutWrapper">
                <div className='AboutCopy' ref={aboutRef}>
                  	<h5>A few words about me</h5>
                  	  <h1>
                  	    I'm Andreas Pantel, a passionate multidisciplinary designer with skills in Web, 3D, UX & UI design who focuses on crafting exceptional visual journeys with clean code and impactful graphics.
                  	  </h1>
                </div>
              </div>
                <div className="Wrapper ServiceWrapper" ref={skillsRef}>
                  <div className='Services'>
                    <div className="AboutServices">
                      <h5>Services</h5>
                      <h4>
                          Web&nbsp;&&nbsp;Mobile <span>/ </span> 
                          3D&nbsp;Design <span>/ </span> 
                          UX&nbsp;&&nbsp;UI <span>/ </span>  
                          Animation <span>/ </span> 
                          Art&nbsp;Direction <span>/ </span> 
                          Brand&nbsp;Identity <span>/ </span> 
                          Photography <span>/ </span> 
                          Print&nbsp;&&nbsp;Product Design
                      </h4>
                    </div>
                    <div className="AboutTools">
                      <h5>Tools</h5>
                      <h4>
                          HTML&nbsp;5 <span>/ </span> 
                          CSS&nbsp;3 <span>/ </span> 
                          JavaScript <span>/ </span> 
                          React <span>/ </span> 
                          R3F <span>/ </span> 
                          Three.js <span>/ </span> 
                          WebGL <span>/ </span> 
                          Blender <span>/ </span> 
                          Unreal&nbsp;Engine <span>/ </span>
                          Figma <span>/ </span> 
                          XD <span>/ </span>
                          Photoshop <span>/ </span> 
                          InDesign <span>/ </span> 
                          Illustrator
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="Wrapper ExpWrapper" ref={expRef}>
                  <div>
                    <hr />
                    <h5>Experience</h5>
                    <h1>Over 8 years of experience</h1>
                    <h3>
                      In 2015 I began my creative journey, studying Game Art and 3D Animation. With diverse roles in marketing, web development, design and project management teams, I've enjoyed crafting many different successful projects like 3D walkthroughs, interfaces and web projects as well as print and packaging.
                    </h3>
                    <hr />
                  </div>
                </div>
              <div className="Wrapper SliderWrapper AboutSlider" ref={sliderRef}>
                <AboutSlider />
                <h3 id='about-img'>
                  When having free time, I enjoy making music, taking pictures when traveling, exploring new locations with my motorcycle and spending precious time with friends and family.
                </h3>
              </div>
            </div>
            <Cta />
        </>
    )
}