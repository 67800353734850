import React, { useState } from 'react';
import Landing from './Landing'
import Gallery from './Gallery'
import Cta from '/components/elements/Cta.jsx'


export default function Home()
{
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);

    return(
        <>
            <Landing 
                isAnimationComplete={isAnimationComplete} 
                setIsAnimationComplete={setIsAnimationComplete} 
            />
            <Gallery />
            <Cta />
        </>
    )
}
