import React, { useEffect } from "react";
import { preLoaderAnim } from "./animations";

const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);

  return (
    <div className="PreLoader">
      <div className="LogoWrapper">
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Ebene 2" width={400} height={400}>
          <g data-name="Ebene 1">
            <path className='LoaderPathLogo' fill="var(--dark)" d="M146.32 208.88h-14.24l16.73-54.2 16.84 54.2 18.12 58.2h40.44L166.5 110.92h-35.39L73.84 267.08h40.22l9.01-29.21h31.89l-8.64-28.99zM296.6 136.24c-5.04-8.1-12.16-14.35-21.34-18.74-9.19-4.39-19.75-6.59-31.69-6.59h-57.29l10.68 28.99H244c6.79.07 12.15 2.29 16.09 6.65s5.9 10.19 5.9 17.48c0 6.65-1.92 11.79-5.74 15.44-3.83 3.64-9.39 5.47-16.68 5.47h-30.03l10.68 28.99h18.7c19.02 0 33.98-4.55 44.88-13.65 10.9-9.1 16.36-21.25 16.36-36.46 0-10.28-2.52-19.47-7.56-27.57Z"/>
            <circle className='LoaderPathCircle' cx="189" cy="189" r="184" fill="none" stroke="var(--dark)" strokeMiterlimit="10" strokeWidth="10"/>
          </g>
        </svg>
        <div className='LoadingText'>
          <h5>Loading<span>.</span><span>.</span><span>.</span></h5>
        </div>
      </div>
    </div>
  );
};

export default PreLoader;