export default function Cta()
{
    return(
        <div className='Wrapper Cta'>
            <div className="SectionTitle">
                <h5>Need a Designer ?</h5>
                <a className="FooterCtaLink" href="/contact">
                    <h1>
                        Let's work together
                        <span className='Boxicon lg'>
                            <box-icon className='BoxiconArrow' size='60px' name='right-arrow-alt'></box-icon>
                        </span>
                    </h1>
                </a>
            </div>
        </div>
    )
}